
const highlightLetters = (text,searchText,lengthT,_cutText) => {
    const lengthText = lengthT;
    const code = "span"+Math.random();
    const parser = new DOMParser();
    const cutText = _cutText;
    const doc = parser.parseFromString(text, "text/html");

    // Iterar a través de los nodos y renderizar solo los elementos <span> y nodos de texto
    renderSuitableNodes(doc.body,code);
      const regex = new RegExp(`(${searchText})`, "gi");   

    const resultParcial = doc.body.innerHTML;
    if(searchText){
        if(cutText){
            let start, end, center; 
            const lowerCaseResultParcial = resultParcial.toLowerCase();
            const lowerCaseSearchText = searchText.toLowerCase();
            const indexStart = lowerCaseResultParcial.indexOf(lowerCaseSearchText); 
            if (indexStart !== -1) {
            start = (indexStart > lengthText) ? `...${resultParcial.substring(indexStart - lengthText, indexStart)}` : resultParcial.substring(0, indexStart); 
            end = resultParcial.substring(indexStart + searchText.length, indexStart + searchText.length + lengthText) + (indexStart + searchText.length + lengthText < resultParcial.length ? '...' : '');
            center = resultParcial.substring(indexStart, indexStart + searchText.length);
            } 
            doc.body.innerHTML = start +'<'+code+' style="color: #0d7260;font-weight: 900;">'+center+'</'+code+'>'+end;
        }else{
            doc.body.innerHTML = doc.body.innerHTML.replace(regex, '<'+code+' style="color: #0d7260;font-weight: 900;">$1</'+code+'>');
        }
 

        return doc.body.innerHTML;   
    }else{
        return resultParcial;
    }
 
}

const renderSuitableNodes=(parent,code) => {
    const nodosAdecuados = [];
    parent.childNodes.forEach(node => {
      if (node.nodeType === Node.ELEMENT_NODE && node.nodeName === code) { 
        this.renderSuitableNodes(node,code);
        nodosAdecuados.push(node);
      } else if (node.nodeType === Node.TEXT_NODE) { 
        const nuevoNodoTexto = document.createTextNode(node.textContent);
        node.replaceWith(nuevoNodoTexto);
        nodosAdecuados.push(nuevoNodoTexto);
      } else if (node.nodeType === Node.ELEMENT_NODE) { 
        const nuevoNodoTexto = document.createTextNode(node.outerHTML);
        node.replaceWith(nuevoNodoTexto);
        nodosAdecuados.push(nuevoNodoTexto);
      }
    });

    parent.innerHTML = '';

    nodosAdecuados.forEach(nodo => {
      parent.appendChild(nodo);
    });
}

export { highlightLetters }