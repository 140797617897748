<template>
  <!-- File Preview -->
  <div class="borderWithDots">
    <!-- Close Button -->
    <feather-icon
      icon="XIcon"
      size="20"
      class="text-secondary mb-2 d-flex justify-content-right ml-auto cursor-pointer"
      @click="$emit('closeMediaUploader')"
    />
    <!-- Selected File -->
    <div>
      <div class="d-flex align-items-start justify-content-center">
        <b-img
          v-if="
            files[currentFileIndex] != null &&
            isImage(files[currentFileIndex].file)
          "
          :src="files[currentFileIndex].file.url"
          class="selected-file"
        ></b-img>

        <div v-else class="selected-file bg-gray">
          <PDFSvg
            class="w-100 h-100"
            v-if="getExtension(files[currentFileIndex].file.name) === 'pdf'"
          />
          <ExcelSvg
            class="w-100 h-100"
            v-else-if="
              getExtension(files[currentFileIndex].file.name) === 'xls' ||
              getExtension(files[currentFileIndex].file.name) === 'xlsx'
            "
          />
          <DocumentSvg class="w-100 h-100" v-else />
        </div>
      </div>

      <div class="d-flex align-items-start justify-content-center mt-1">
        <span>
          {{ files[currentFileIndex].file.name }}
          <span class="ml-1">
            ({{ files[currentFileIndex].file.size / 1000 }} KB)
          </span>
        </span>
      </div>
    </div>
    <!-- Another files with preview -->
    <div
      class="d-flex align-items-center mt-1"
      :class="
        files.length >= 11 ? 'justify-content-between' : 'justify-content-start'
      "
    >
      <div
        :class="files.length >= 11 ? 'file-container-wrapper' : ''"
        @wheel="handleWheel"
        ref="fileContainerWrapper"
      >
        <div
          v-for="(file, index) in files"
          :key="index"
          class="file-container mb-1"
          v-b-tooltip.hover.top="file.file.name"
        >
          <div v-if="isImage(file.file)" :key="index" class="file-container">
            <b-img
              :src="file.file.url"
              class="another-file"
              @click="changeCurrentFile(index)"
            ></b-img>
            <feather-icon
              style="right: 5; top: 7; background-color: #000000"
              icon="XIcon"
              class="cursor-pointer text-white position-absolute"
              @click="files.splice(index, 1)"
            />
          </div>
          <div
            class="file-container other-extension"
            v-else-if="!isImage(file.file)"
            :key="index"
            @click="changeCurrentFile(index)"
          >
            <PDFSvg v-if="getExtension(file.file.name) === 'pdf'" />
            <ExcelSvg
              v-else-if="
                getExtension(file.file.name) === 'xls' ||
                getExtension(file.file.name) === 'xlsx'
              "
            />
            <DocumentSvg v-else />
            <feather-icon
              style="right: 5; top: 7; background-color: #000000"
              icon="XIcon"
              class="cursor-pointer text-white position-absolute"
              @click="files.splice(index, 1)"
            />
          </div>
        </div>
      </div>
      <!-- Add more files -->
      <b-avatar
        :class="files.length >= 11 ? 'mb-1' : 'ml-1 '"
        style="display: inline-block"
        button
        @click="attachAdditionalFiles()"
        class="mt-05"
        square
        size="100px"
      >
        <span style="font-size: 2rem" v-if="files.length >= 10"
          >+{{ files.length - 10 }}</span
        >
        <span style="font-size: 2rem" v-else>+</span>
      </b-avatar>
    </div>
    <input
      class="d-none"
      type="file"
      :accept="acceptedFileTypes"
      ref="additionalFiles"
      multiple
      @change="onAdditionalFileChange"
    />
  </div>
</template>

<script>
import DocumentSvg from "@/assets/images/icons/request-chat/document.vue";
import PDFSvg from "@/assets/images/icons/request-chat/pdf.vue";
import ExcelSvg from "@/assets/images/icons/request-chat/excel.vue";

export default {
  props: {
    files: {
      type: Array,
      required: true,
    },
  },
  components: {
    DocumentSvg,
    PDFSvg,
    ExcelSvg,
  },
  data() {
    return {
      acceptedFileTypes:
        "image/*, audio/*, video/*, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx",
      currentFileIndex: 0,
    };
  },
  methods: {
    changeCurrentFile(index) {
      this.currentFileIndex = index;
    },
    isImage(file) {
      const allowedExtensions = ["png", "jpg", "jpeg", "gif", "svg"];
      const fileExtension = this.getExtension(file.name);
      return allowedExtensions.includes(fileExtension);
    },
    getExtension(filename) {
      return filename.split(".").pop();
    },
    handleWheel(event) {
      if (
        event.target.closest(".file-container-wrapper") ===
        this.$refs.fileContainerWrapper
      ) {
        event.preventDefault();
        this.$refs.fileContainerWrapper.scrollLeft += event.deltaY;
      }
    },
    onAdditionalFileChange(e) {
      const fileArray = Array.from(e.target.files);
    //   this.$emit("onAdditionalFileChange", fileArray);
      // console.log("fileArray", fileArray);
      fileArray.forEach((file) => {
        const imageUrl = URL.createObjectURL(file);
        const newFile = {
          file,
        };
        file.url = imageUrl;
        this.$emit("onAdditionalFileChange", newFile);
      });
    },
    attachAdditionalFiles() {
      this.$refs.additionalFiles.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.borderWithDots {
  position: relative;
  padding: 20px;
  width: 100%;
  height: 66vh;
  min-width: 100%;
  min-height: 66vh;

  .selected-file {
    min-width: 350px;
    max-width: 350px;
    min-height: 350px;
    max-height: 350px;
  }
  .file-container {
    margin: 0.5rem;
    display: inline-block;
    height: 100px;
    max-width: 100px;
    max-height: 100px;
    min-width: 100px;
    min-height: 100px;
    position: relative;
  }
  .file-container-wrapper {
    overflow-x: auto;
    white-space: nowrap;
    max-width: 90%;
    overflow-y: hidden;
    display: inline-block;
    user-select: none;
  }
  .another-file {
    height: 100px;
    object-fit: cover;
    max-width: 100px;
    max-height: 100px;
    min-width: 100px;
    min-height: 100px;
    border-radius: 5%;
  }
  .file-container-wrapper::-webkit-scrollbar {
    height: 10px !important;
  }

  .other-extension {
    border: 1px solid #000000;
    border-radius: 5%;
  }
}

.borderWithDots::before {
  padding: 10px !important;
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: 2px dashed #000;
  pointer-events: none;
  z-index: 999999999; // 9
  border-radius: 2%;
}

.dark-layout {
  .borderWithDots::before {
    border: 2px dashed #0b213f;
  }
}
</style>