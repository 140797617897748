var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"borderWithDots"},[_c('feather-icon',{staticClass:"text-secondary mb-2 d-flex justify-content-right ml-auto cursor-pointer",attrs:{"icon":"XIcon","size":"20"},on:{"click":function($event){return _vm.$emit('closeMediaUploader')}}}),_c('div',[_c('div',{staticClass:"d-flex align-items-start justify-content-center"},[(
          _vm.files[_vm.currentFileIndex] != null &&
          _vm.isImage(_vm.files[_vm.currentFileIndex].file)
        )?_c('b-img',{staticClass:"selected-file",attrs:{"src":_vm.files[_vm.currentFileIndex].file.url}}):_c('div',{staticClass:"selected-file bg-gray"},[(_vm.getExtension(_vm.files[_vm.currentFileIndex].file.name) === 'pdf')?_c('PDFSvg',{staticClass:"w-100 h-100"}):(
            _vm.getExtension(_vm.files[_vm.currentFileIndex].file.name) === 'xls' ||
            _vm.getExtension(_vm.files[_vm.currentFileIndex].file.name) === 'xlsx'
          )?_c('ExcelSvg',{staticClass:"w-100 h-100"}):_c('DocumentSvg',{staticClass:"w-100 h-100"})],1)],1),_c('div',{staticClass:"d-flex align-items-start justify-content-center mt-1"},[_c('span',[_vm._v(" "+_vm._s(_vm.files[_vm.currentFileIndex].file.name)+" "),_c('span',{staticClass:"ml-1"},[_vm._v(" ("+_vm._s(_vm.files[_vm.currentFileIndex].file.size / 1000)+" KB) ")])])])]),_c('div',{staticClass:"d-flex align-items-center mt-1",class:_vm.files.length >= 11 ? 'justify-content-between' : 'justify-content-start'},[_c('div',{ref:"fileContainerWrapper",class:_vm.files.length >= 11 ? 'file-container-wrapper' : '',on:{"wheel":_vm.handleWheel}},_vm._l((_vm.files),function(file,index){return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(file.file.name),expression:"file.file.name",modifiers:{"hover":true,"top":true}}],key:index,staticClass:"file-container mb-1"},[(_vm.isImage(file.file))?_c('div',{key:index,staticClass:"file-container"},[_c('b-img',{staticClass:"another-file",attrs:{"src":file.file.url},on:{"click":function($event){return _vm.changeCurrentFile(index)}}}),_c('feather-icon',{staticClass:"cursor-pointer text-white position-absolute",staticStyle:{"right":"5","top":"7","background-color":"#000000"},attrs:{"icon":"XIcon"},on:{"click":function($event){return _vm.files.splice(index, 1)}}})],1):(!_vm.isImage(file.file))?_c('div',{key:index,staticClass:"file-container other-extension",on:{"click":function($event){return _vm.changeCurrentFile(index)}}},[(_vm.getExtension(file.file.name) === 'pdf')?_c('PDFSvg'):(
              _vm.getExtension(file.file.name) === 'xls' ||
              _vm.getExtension(file.file.name) === 'xlsx'
            )?_c('ExcelSvg'):_c('DocumentSvg'),_c('feather-icon',{staticClass:"cursor-pointer text-white position-absolute",staticStyle:{"right":"5","top":"7","background-color":"#000000"},attrs:{"icon":"XIcon"},on:{"click":function($event){return _vm.files.splice(index, 1)}}})],1):_vm._e()])}),0),_c('b-avatar',{staticClass:"mt-05",class:_vm.files.length >= 11 ? 'mb-1' : 'ml-1 ',staticStyle:{"display":"inline-block"},attrs:{"button":"","square":"","size":"100px"},on:{"click":function($event){return _vm.attachAdditionalFiles()}}},[(_vm.files.length >= 10)?_c('span',{staticStyle:{"font-size":"2rem"}},[_vm._v("+"+_vm._s(_vm.files.length - 10))]):_c('span',{staticStyle:{"font-size":"2rem"}},[_vm._v("+")])])],1),_c('input',{ref:"additionalFiles",staticClass:"d-none",attrs:{"type":"file","accept":_vm.acceptedFileTypes,"multiple":""},on:{"change":_vm.onAdditionalFileChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }